import React, {useState, useEffect} from "react";
import Container from '@mui/material/Container';
import { AnimatePresence, motion } from "framer-motion"
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import logo from './assets/logo.png'
import { Helmet } from "react-helmet";
import suamelhorversao from './assets/suamelhorversao.png'
import './App.css';
import Depoimentos from './component/Depoimentos';
import Whats from './component/Whatsapp';
import { borderBottomColor, borderColor, borderRadius, borderRightColor, positions } from "@mui/system";
import Agende from "./component/Agende";
import QuemSomos from "./pages/QuemSomos";
import Tratamentos from "./pages/Tratamentos";
import Promocoes from "./pages/Promocoes";
import Contato from "./pages/Contato";
import Footer from "./component/Footer";

export default function Home() {
  const [showInternas, setShowInternas] = useState(false)
  const [title, setTitle] = useState('')
  const [openInterna, setOpenInterna] = useState(0)
  const width = window.innerWidth
  const height = window.innerHeight

  const styles = {
    internaBox: {
      position: 'absolute',
      zIndex: 50,
      top: 220,
      left: 499,
      backgroundColor: '#ffffffE6',
      borderTopLeftRadius: 20,
    },
    title: {
      fontSize: 40,
      fontFamily: 'Roboto Condensed',
      color: '#16376e',
      width: 500
    }
  }

  function ShowVai(value, title) {
    setTitle(title)
    if(value === 0){
      setShowInternas(false)
    } else {
      setShowInternas(true)
    }
    setOpenInterna(value)
  }


  return (
    <>
    <motion.div className='opaco'
        initial={{y: [0, 0], opacity: 1 }}
        animate={{y: [0, -2000], opacity: 0 }}
        transition={{ delay: 1, duration: 2 }}
        >
    </motion.div>
    <motion.div className='opaco' style={{backgroundColor: '#ffffff'}}
        initial={{y: 608, x: [0, 0], opacity: 1 }}
        animate={{y: 608, x: [0, 2000], opacity: 0 }}
        transition={{ delay: 3, duration: 1 }}
        >
    </motion.div>
      <motion.div
        initial={{opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 4 }}
        >
        <Container maxWidth="lg" className='container'>
          
          <div style={{height: 620}}>

            <Button component={Link} to="/" style={{backgroundColor: 'transparent', border: 'none'}}>
              <img src={logo} width='320px' style={{marginTop: 20}} />
            </Button>


              <div style={{fontFamily: 'Roboto Light Italic', marginTop: 30, fontSize: 20, color: '#99a8a9'}}>
              Clínica estética <br />
              exclusiva para homens
              </div>
              <div style={{display: 'flex', flexDirection: 'column', marginTop: 40}}>
              {width > 1367 ? 
                <>
                  <Button className='menuLateral' component={Link} to="/quem-somos">Quem somos</Button>
                  <Button className='menuLateral' component={Link} to="/tratamentos">Tratamentos</Button>
                  <Button className='menuLateral' component={Link} to="/promocoes">Promoções</Button>
                  <Button className='menuLateral' component={Link} to="/contato">Agende sua avaliação</Button>
                </>
                : 
                <>
                  <Button className='menuLateralSmallScreen' component={Link} to="/quem-somos">Quem somos</Button>
                  <Button className='menuLateralSmallScreen' component={Link} to="/tratamentos">Tratamentos</Button>
                  <Button className='menuLateralSmallScreen' component={Link} to="/promocoes">Promoções</Button>
                  <Button className='menuLateralSmallScreen' component={Link} to="/contato">Agende sua avaliação</Button>
                </>
                }
              </div>
          </div>
          
          {showInternas ?
          
            <motion.div style={styles.internaBox} className="bordaInterna" initial={{width: 0, height: 0}} animate={{width: width-737, height: 1000}} exit={{opacity: 0}}>
                
                {/* <button onClick={()=>{setTitle(''); setOpenInterna(0); setShowInternas(false)}} className="sair">/ home</button> */}
                
                <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>
                    
                {openInterna === 1 ? <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.2, duration: 1}} style={styles.title}>{title}</motion.div> : <></>}
                {openInterna === 2 ? <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.2}} style={styles.title}>{title}</motion.div> : <></>}
                {openInterna === 3 ? <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.2}} style={styles.title}>{title}</motion.div> : <></>}
                {openInterna === 4 ? <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.2}} style={styles.title}>{title}</motion.div> : <></>}
                      
                      <motion.div            
                          initial={{opacity: 0 }}
                          animate={{opacity: 1 }} className='boxRounded4'>
                        <Agende />
                      </motion.div>
                </div>

                {openInterna === 1 ? <QuemSomos /> : <></>}
                {openInterna === 2 ? <Tratamentos /> : <></>}
                {openInterna === 3 ? <Promocoes /> : <></>}
                {openInterna === 4 ? <Contato /> : <></>}
                {showInternas ? <Footer /> : null}
                
            </motion.div>

          : 
          <></>
          }

          <div style={{paddingTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Whats />


              {/* showInternas ?
                <>
                <motion.div            
                    initial={{opacity: 1, display: 'block' }}
                    animate={{opacity: 0, display: 'none' }} className='boxRounded2'>
                  <Agende />
                </motion.div>
                </>
              : 
                <motion.div            
                    initial={{opacity: 0, display: 'none' }}
                    animate={{opacity: 1, display: 'block' }} className='boxRounded2'>
                  <Agende />
                </motion.div>
              */}
            
            </div>
            <div>
              <img src={suamelhorversao} width='350' style={{marginRight: 50}} />
            </div>
          </div>
          
          <div style={{height: 380}}>
            {showInternas ? null : <Depoimentos />}
          </div>

          <Footer />
        </Container>
      </motion.div>
    </>
  );

 
}
