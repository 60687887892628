import React from "react";
import '../App.css'
import agende from '../assets/agende.png'
import { Link } from 'react-router-dom';
import { Button } from "@mui/material";

export default function Agende() {

    return(
        <div style={{marginTop: 0}}>
            <Button component={Link} to="/contato">
                <img src={agende} width='380' />
            </Button>
        </div>
    )
}