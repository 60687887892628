import React from "react";
import '../App.css';
import { Helmet } from "react-helmet";
import { AnimatePresence, motion } from "framer-motion";
import Layout from "../component/Layout";
import { isMobile } from "react-device-detect";
import promocoes from '../assets/promocoes.png'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'AW-16639841404'
}
TagManager.initialize(tagManagerArgs)


export default function Promocoes() {
    window.dataLayer.push({
        event: 'pageview'
      });
    
    return(
    <Layout>
        <div style={{marginBottom: 50, height: 800}}>
        <AnimatePresence>
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.5, duration: 2}} exit={{opacity: 0}}>
                <div style={{display: 'flex', flexDirection: isMobile ?  'column-reverse' : 'row'}}>
                    <div style={{marginTop: 40}}>
                        Na Homenz Americana, toda segunda feira é especial! 
                        <br /><br />
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15}}>Consulte condições.</div>
                    </div>
                    <div>
                        <img src={promocoes} width={isMobile ? '100%' : '450'} />
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
        </div>
    </Layout>
    )
}