import React, {useState, useEffect} from "react";
import Container from '@mui/material/Container';
import { motion } from "framer-motion"
import logo from '../assets/logo.png'
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isMobile } from "react-device-detect";

import suamelhorversao from '../assets/suamelhorversao.png'
import '../App.css';
import Whats from '../component/Whatsapp';
import Agende from "../component/Agende";
import Footer from "../component/Footer";
import voltar from '../assets/voltar.png'
import BasicMenu from "../MenuMogile";

export default function Layout(props) {
  const [showInternas, setShowInternas] = useState(true)
  const title = props.title
  const openInterna = props.openInterna
  const width = window.innerWidth
  const height = window.innerHeight
  const rota  = useLocation()

  const styles = {
    internaBox: {
      position: 'absolute',
      marginLeft: 334,
      top: 220,
      backgroundColor: '#ffffff',
      borderTopLeftRadius: 20,
    },
    title: {
      fontSize: 40,
      fontFamily: 'Roboto Condensed',
      color: '#16376e',
      width: 500
    }
  }

  return (

    isMobile ? 

    <>
        <Container className='containerMobile'>
          <BasicMenu />
          <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <Button component={Link} to="/" style={{backgroundColor: 'transparent', border: 'none'}}>
              <img src={logo} width='250px' style={{marginTop: 200, marginLeft: 15}} />
            </Button>
              <div style={{fontFamily: 'Roboto Light Italic', marginTop: 30, fontSize: 20, color: '#99a8a9'}}>
              Clínica estética exclusiva para homens
              </div>
          </div>
          {showInternas ?
          
          <div>
              
              <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>
                  
              <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.2, duration: 1}} style={styles.title}>{title}</motion.div>
              
              </div>

              <Button component={Link} to="/" style={{backgroundColor: 'transparent', border: 'none'}}>
                <img src={voltar} width='50px' style={{marginTop: -10}} />
              </Button>
              {props.children}
          
          </div>
          
        : 
        <></>
        }
          <div style={{paddingTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Whats />
            </div>
            <div>
              <img src={suamelhorversao} width='250' style={{marginRight: (innerWidth/2)-125}} />
            </div>
          </div>
          <Footer />
        </Container>
    </>

      :
    
    <>
        <Container maxWidth="lg" className='container'>
          <div style={{height: 620}}>
            <Button component={Link} to="/" style={{backgroundColor: 'transparent', border: 'none'}}>
              <img src={logo} width='320px' style={{marginTop: 20}} />
            </Button>
              <div style={{fontFamily: 'Roboto Light Italic', marginTop: 30, fontSize: 20, color: '#99a8a9'}}>
              Clínica estética <br />
              exclusiva para homens
              </div>
              <div style={{display: 'flex', flexDirection: 'column', marginTop: 40}}>
                
                  <Button style={{height: width > 1367 ? 70 : 50}} className='menuLateralSmallScreen' component={Link} to="/quem-somos">Quem somos</Button>
                  <Button style={{height: width > 1367 ? 70 : 50}} className='menuLateralSmallScreen' component={Link} to="/tratamentos">Tratamentos</Button>
                  <Button style={{height: width > 1367 ? 70 : 50}} className='menuLateralSmallScreen' component={Link} to="/promocoes">Promoções</Button>
                  <Button style={{height: width > 1367 ? 70 : 50}} className='menuLateralSmallScreen' component={Link} to="/contato">Agende sua avaliação</Button>
                
              </div>
          </div>
          {showInternas ?
          
            <motion.div style={styles.internaBox} className="bordaInterna" initial={{width: 0, height: 0}} animate={{width: 840, height: 1000}} exit={{opacity: 0}}>
                
                <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>
                    
                <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.2, duration: 1}} style={styles.title}>{title}</motion.div>
                
                </div>

                <Button component={Link} to="/" style={{backgroundColor: 'transparent', border: 'none'}}>
                  <img src={voltar} width='50px' style={{marginTop: -90}} />
                </Button>
                {props.children}
            
            <div style={{position: 'absolute', left: -300}}>
              <Footer />
            </div>
            </motion.div>
            
          : 
          <></>
          }

          <div style={{paddingTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Whats />            
            </div>
            <div>
              <img src={suamelhorversao} width='350' style={{marginRight: 50}} />
            </div>
          </div>
          
          <div style={{height: 380}}>
            
          </div>

        </Container>

      </>
        
  );

 
}
