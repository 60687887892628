import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";

const firebaseConfig = {
  apiKey: "AIzaSyAK3bN824aIRY6GYPJs8GI5_Z7rlgSwA1w",
  authDomain: "homenz-2f6e2.firebaseapp.com",
  projectId: "homenz-2f6e2",
  storageBucket: "homenz-2f6e2.appspot.com",
  messagingSenderId: "691620246510",
  appId: "1:691620246510:web:58444115e71c363b54d4ff",
  measurementId: "G-WTDSSEK0G9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

import '../App.css';
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { IMaskInput } from "react-imask";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { doc, setDoc, set, collection, query, where, getDocs } from "firebase/firestore";
import sucesso from '../assets/sucesso.png'
import Layout from "../component/Layout";
import mapa from '../assets/mapa.png'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'AW-16639841404'
}
TagManager.initialize(tagManagerArgs)



export default function Contato() {
    window.dataLayer.push({
        event: 'pageview'
      });
    

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [celular, setCelular] = useState('');
    const [readDocs, setReadDocs] = useState(false)
    const [showForm, setShowForm] = useState(true)
    const navigate = useNavigate()

        
    async function SaveData() {
        const data = { nome, email, celular };
        try {
            await setDoc(doc(db, "Homenz", email), data);
            console.log('deu certo');
            navigate('/contato-ok')
            //setShowForm(false)
        } catch (error) {
            console.log('deu errado', error);
        }
    }
    

    return(
    <Layout>
        <Helmet>
            <title>Homenz || Contato</title>
            
            <meta name="description" content="Fale com nossa equipe de especialistas e agende sua avaliação gratuita" />
            <meta nema="keywords" content="homenz americana, clínica estética em americana, clínica estética para homens, Clínica estética exclusiva para homens, saúde e estética masculina" />
        </Helmet>        
        <div style={{minHeight: 900}}>
        <AnimatePresence>
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.5, duration: 2}} exit={{opacity: 0}}>
                <div style={{fontFamily: 'Roboto Italic', fontSize: 35, color: '#79bcc1'}}>Transforme-se AGORA!</div>
                <div style={{fontFamily: 'Roboto Italic', fontSize: 25, color: '#79bcc1'}}>Agende sua avaliação gratuita</div>
                
                {/* <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>Nome:</div> */}
                {showForm ? 
                <>
                    <div style={{marginTop: 50}}>
                        <TextField sx={{ m: 1, width: isMobile ? '35ch' : '80ch' }} label="Digite seu email"
                            id="name"
                            name="name"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)} />


                        <TextField sx={{ m: 1, width: isMobile ? '35ch' : '80ch' }} label="Digite seu nome"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                        
                    </div>
                    <div style={{marginTop: 10, marginBottom: 20}}>
                        <IMaskInput className="inputMasked"
                            mask="(00) 00000-0000"
                            placeholder="Digite seu celular"
                            id="celular"
                            name="celular"
                            value={celular}
                            onChange={(e) => setCelular(e.target.value)}
                            />
                    </div>
                    <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798', marginTop: 10, marginLeft: 10}}>
                        <FormControlLabel
                            value={readDocs}
                            onChange={() => setReadDocs(!readDocs)}
                            control={<Switch color="primary" />}
                            label="Declaro que conheço a Política de Privacidade e autorizo a utilização das minhas informações."
                            labelPlacement="end"
                            />
                    </div>
                    <button onClick={()=>{readDocs ? SaveData() : null }} className={readDocs ? "enviar" : "enviarOff"}>
                        Enviar    
                    </button>
                </>

                : 
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 80}}>
                        <img src={sucesso} width='150' />
                        <br />
                        Sua mensagem foi enviada. Em breve, retornaremos.
                    </div>
                }
                <img src={mapa} width='100%' style={{borderRadius: 10, marginTop: 30}} />
            </motion.div>
        </AnimatePresence>
        </div>
    </Layout>
    )
}