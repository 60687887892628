import React, {useState, useEffect} from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import {isMobile} from 'react-device-detect';
import { Helmet } from "react-helmet";
import Home from "./Home";
import HomeMobile from "./HomeMobile";
import QuemSomos from "./pages/QuemSomos";
import Tratamentos from "./pages/Tratamentos";
import Promocoes from "./pages/Promocoes";
import Contato from "./pages/Contato";
import Agende from "./component/Agende";
import Facebook from "./utils/meta";
import TagManager from 'react-gtm-module'
import ContatoOk from "./pages/ContatoOk";

const tagManagerArgs = {
  gtmId: 'AW-16639841404'
}
TagManager.initialize(tagManagerArgs)


export default function App() {
  window.dataLayer.push({
    event: 'pageview'
  });

  return (
    <>
     <Helmet>
        <title>Homenz || Home</title>
        <meta name="description" content="Clínica estética exclusiva para homens em Americana"  />
        <meta nema="keywords" content="homenz americana, clínica estética em americana, clínica estética para homens, Clínica estética exclusiva para homens, saúde e estética masculina" />
      </Helmet>
    <div style={{position: 'fixed', zIndex: 998, top: -10, left: (window.innerWidth/2)-190}}>
      <Agende />
    </div>
    <Routes>
      <Route path="/" element={isMobile ? <HomeMobile /> : <Home />} />
      <Route path="/quem-somos" element={<QuemSomos />} />
      <Route path="/tratamentos" element={<Tratamentos />} />
      <Route path="/tratamento-facial" element={<Tratamentos />} />
      <Route path="/tratamento-capilar" element={<Tratamentos />} />
      <Route path="/tratamento-corporal" element={<Tratamentos />} />
      <Route path="/depilacao-a-led" element={<Tratamentos />} />
      <Route path="/promocoes" element={<Promocoes />} />
      <Route path="/contato" element={<Contato />} />
      <Route path="/contato-ok" element={<ContatoOk />} />
    </Routes>
    <Facebook />
        
    </>
  );

 
}
