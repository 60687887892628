import React, {useState, useEffect} from "react";
import Container from '@mui/material/Container';
import { AnimatePresence, motion } from "framer-motion"
import { Link } from 'react-router-dom';
import logo from './assets/logo.png'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Helmet } from "react-helmet";
import suamelhorversao from './assets/suamelhorversao.png'
import './App.css';
import Depoimentos from './component/Depoimentos';
import Whats from './component/Whatsapp';
import Footer from "./component/Footer";
import BasicMenu from "./MenuMogile";


export default function HomeMobile() {
  const [showInternas, setShowInternas] = useState(false)
  const [title, setTitle] = useState('')
  const [openInterna, setOpenInterna] = useState(0)
  const width = window.innerWidth
  const height = window.innerHeight

  const styles = {
    internaBox: {
      position: 'absolute',
      zIndex: 50,
      top: 220,
      left: 499,
      backgroundColor: '#ffffffE6',
      borderTopLeftRadius: 20,
    },
    title: {
      fontSize: 40,
      fontFamily: 'Roboto Condensed',
      color: '#16376e',
      width: 500
    }
  }

  function ShowVai(value, title) {
    setTitle(title)
    if(value === 0){
      setShowInternas(false)
    } else {
      setShowInternas(true)
    }
    setOpenInterna(value)
  }


  return (
    <>
        <Container className='containerMobile'>
          <BasicMenu />
          <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <Button component={Link} to="/" style={{backgroundColor: 'transparent', border: 'none'}}>
              <img src={logo} width='250px' style={{marginTop: 200, marginLeft: 15}} />
            </Button>
              <div style={{fontFamily: 'Roboto Light Italic', marginTop: 30, fontSize: 20, color: '#99a8a9'}}>
              Clínica estética exclusiva para homens
              </div>
          </div>
          <div style={{paddingTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Whats />
            </div>
            <div>
              <img src={suamelhorversao} width='250' style={{marginRight: (innerWidth/2)-125}} />
            </div>
          </div>
          <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            {showInternas ? null : <Depoimentos />}
          </div>
          <Footer />
        </Container>
    </>
  );

 
}
