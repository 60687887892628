import React, { useState, useEffect } from "react";
import '../App.css';
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import icoArrowUp from '../assets/icoArrowDown.png'
import icoArrowDown from '../assets/icoArrowUp.png'
import Layout from "../component/Layout";
import { useLocation } from "react-router-dom";

import corporal from '../assets/corporal.png'
import facial from '../assets/facial.png'
import depilacao from '../assets/depilacao.png'
import capilar from '../assets/capilar.png'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'AW-16639841404'
}
TagManager.initialize(tagManagerArgs)


export default function Tratamentos() {
    window.dataLayer.push({
        event: 'pageview'
      });
    
    const rota = useLocation()
    const [tratamento, setTratamento] = useState(0)

    function Abre(value) {
        setTratamento(value)
    }

    useEffect(()=>{
        if(rota.pathname === '/tratamento-facial'){
            setTratamento(3)
        } 
        if(rota.pathname === '/tratamento-capilar'){
            setTratamento(2)
        } 

        if(rota.pathname === '/tratamento-corporal'){
            setTratamento(4)
        } 
        if(rota.pathname === '/depilacao-a-led'){
            setTratamento(1)
        } 
    }, [])

    return(
    <Layout>

        <Helmet>
            <title>Homenz || Tratamentos</title>
            <meta name="description" content="Tratamentos e procedimentos estéticos pra homens" />
            <meta nema="keywords" content="homenz americana, clínica estética em americana, clínica estética para homens, Clínica estética exclusiva para homens, saúde e estética masculina, tratamento capilar, depilação a led, tratamento estético corporal, tratamento estético facial" />
        </Helmet>
        <div style={{minHeight: 900}}>
        <AnimatePresence>
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.5, duration: 2}} exit={{opacity: 0}} style={styles.principal}>
    
            <motion.button initial={{opacity: 0}} animate={{opacity: 1}} style={tratamento === 2 ? styles.tituloOn : styles.titulo} onClick={()=>{Abre(tratamento === 2 ? 0 : 2 )}}>Tratamento Capilar <img src={tratamento === 2 ? icoArrowDown : icoArrowUp} width='20' /></motion.button>
                {tratamento === 2 ? 
                    <motion.div style={styles.conteudoInterna} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{duration: 1.6}} exit={{ opacity: 0 }}>
                        <img src={capilar} width='100%' />

                        <div style={{fontFamily: 'Roboto Italic', fontSize: 22, color: '#53aca6', marginBottom: 15, marginTop: 30}}>
                            Tratamentos exclusivos para calvície e queda de cabelo
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 18, color: '#16376e'}}>
                        Na Homenz, oferecemos tratamentos capilares avançados e exclusivos para o público masculino. Focamos em revitalização, fortalecimento e transplante capilar. Descubra soluções personalizadas para a sua necessidade e renove sua autoconfiança com esses métodos inovadores. Nossos revolucionários tratamentos de MMP (Microinfusão de Medicamentos na Pele) e MESOTERAPIA CAPILAR estimulam o couro cabeludo e promovem o crescimento dos fios de maneira minimamente invasiva. A técnica MMP e a MESOTERAPIA CAPILAR são métodos eficazes para tratar a queda de cabelo, mas diferem em suas abordagens e métodos de aplicação. Cada um pode ser ajustado de acordo com as necessidades específicas do paciente, proporcionando um tratamento individualizado e confortável.
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Tratamento de Mesoterapia Capilar
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Revitalize seus cabelos com a mesoterapia capilar! A mesoterapia capilar, um dos métodos mais procurados para combater a calvície, consiste na aplicação de pequenas quantidades de medicamentos específicos diretamente no couro cabeludo, fornecendo nutrientes diretamente aos folículos capilares. Utilizando agulhas finas, as injeções são aplicadas na camada média da pele, onde estão localizados os folículos capilares. Esse tratamento ajuda a aumentar a circulação sanguínea no couro cabeludo, promovendo um ambiente mais saudável para o crescimento e fortalecimento dos fios. Descubra o poder da Mesoterapia Capilar e conquiste cabelos mais fortes e saudáveis!
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            MMP (Microinfusão de Medicamentos na Pele)
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Experimente o MMP e transforme a saúde dos seus cabelos. O MMP utiliza um dispositivo de microagulhas para criar microperfurações no couro cabeludo, permitindo a infusão de medicamentos específicos diretamente nas camadas mais necessitadas da pele. Este processo revitaliza profundamente o couro cabeludo e os folículos capilares, estimulando a regeneração celular, a produção de colágeno e o fortalecimento dos fios, além de melhorar a absorção dos medicamentos. O MMP proporciona resultados visíveis com fios mais fortes e saudáveis!
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Transplante
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Conheça o transplante capilar da Homenz, uma solução exclusiva para homens que buscam restaurar a densidade capilar com naturalidade. Técnica avançada, minimamente invasiva e de recuperação rápida. Renove sua autoconfiança com resultados surpreendentes e duradouros. Agende sua consulta e transforme sua imagem.
                        </div>
                        <Helmet>
                            <title>Homenz || Tratamento Capilar</title>
                            <meta name="description" content="Tratamentos exclusivos para calvície e queda de cabelo" />
                            <meta nema="keywords" content="homenz americana, clínica estética em americana, clínica estética para homens, Clínica estética exclusiva para homens, saúde e estética masculina, tratamento capilar, transplante capilar, mesoterapia capilar, MMP capilar, microinfusão capilar, calvície masculina" />
                        </Helmet>                        
                    </motion.div>
                : <></>}

            <motion.button initial={{opacity: 0}} animate={{opacity: 1}} style={tratamento === 1 ? styles.tituloOn : styles.titulo} onClick={()=>{Abre(tratamento === 1 ? 0 : 1 )}}>Depilação a LED <img src={tratamento === 1 ? icoArrowDown : icoArrowUp} width='20' /></motion.button>
                {tratamento === 1 ? 
                    <motion.div style={styles.conteudoInterna} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{duration: 1.6}} exit={{ opacity: 0 }}>
                        <img src={depilacao} width='100%' />

                        <div style={{fontFamily: 'Roboto Italic', fontSize: 22, color: '#53aca6', marginBottom: 15, marginTop: 30}}>
                            Tecnologia de ponta na remoção de pelos
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 18, color: '#16376e'}}>
                            Transforme sua rotina de cuidados com a pele e desfrute de uma solução moderna e eficiente. A depilação a LED é  a última inovação em autocuidado, oferecendo uma solução eficaz e definitiva para a remoção de pelos. Utilizando a tecnologia de LED, este método proporciona uma experiência mais confortável e segura, ideal para todos os fototipos (tipos de pele).
                        </div>
                        <div style={{fontFamily: 'Roboto Bold', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 30}}>
                            Faixa de barba
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Na Homenz, a depilação a LED para a Faixa de barba é a solução moderna e eficaz para homens que buscam uma aparência bem cuidada e uma linha de barba definida, com conforto e segurança. Este método avançado permite definir o seu estilo de barba com alta precisão, minimiza as irritações e pelos encravados, promovendo resultados de longa duração e uma pele mais lisa e saudável. Indicado para todos os fototipos (cor de pele).
                        </div>
                        <div style={{fontFamily: 'Roboto Bold', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 30}}>
                            Depilação a LED
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Tecnologia de ponta na remoção de pelos (aceitamos sugestões para esse Subtítulo!) Transforme sua rotina de cuidados com a pele e desfrute de uma solução moderna e eficiente. A depilação a LED é a última inovação em autocuidado, oferecendo uma solução eficaz e definitiva para a remoção de pelos. Utilizando a tecnologia de LED, este método proporciona uma experiência mais confortável e segura, ideal para todos os fototipos (tipos de pele).
                        </div>
                        <Helmet>
                            <title>Homenz || Depilação a LED</title>
                            <meta name="description" content="Tecnologia de ponta na remoção de pelos com a depilação a LED" />
                            <meta nema="keywords" content="homenz americana, clínica estética em americana, clínica estética para homens, Clínica estética exclusiva para homens, saúde e estética masculina, depilação a led, depilação corporal, depilação para faixa de barba, depilação masculina" />
                        </Helmet>
                    </motion.div>
                : <></>}

            

            <motion.button initial={{opacity: 0}} animate={{opacity: 1}} style={tratamento === 4 ? styles.tituloOn : styles.titulo} onClick={()=>{Abre(tratamento === 4 ? 0 : 4 )}}>Tratamento Corporal <img src={tratamento === 4 ? icoArrowDown : icoArrowUp} width='20' /></motion.button>
                {tratamento === 4 ? 
                    <motion.div style={styles.conteudoInterna} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{duration: 1.6}} exit={{ opacity: 0 }}>
                        <img src={corporal} width='100%' />

                        <div style={{fontFamily: 'Roboto Italic', fontSize: 22, color: '#53aca6', marginBottom: 15, marginTop: 30}}>
                            Força e definição: Transforme seu corpo na Homenz
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 18, color: '#16376e'}}>
                            Na Homenz elevamos seu esforço na academia a novos patamares com nossos procedimentos estéticos corporais. Nossos tratamentos complementam sua rotina de exercícios, realçando resultados atléticos para um corpo mais forte e definido.
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Aplicação de enzimas para gordura localizada
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Potencialize seus resultados com a aplicação de enzimas, uma técnica poderosa para eliminar gorduras localizadas que resistem aos treinos. Obtenha um contorno corporal mais definido e esculpido, destacando a musculatura trabalhada.
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Criofrequência
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            A Criofrequência na Homenz é sua aliada na busca por um corpo mais saudável. Tratamento estético avançado que combina a ação do frio com o calor para tonificar a pele, tratar flacidez e gordura localizada, realçando a definição muscular e proporcionando resultados visíveis, que complementam sua dedicação na academia.
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Criolipólise
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Descubra a Criolipólise na Homenz, o procedimento perfeito para homens que desejam uma silhueta mais atlética. A Criolipólise utiliza temperaturas frias para congelar e eliminar células de gordura subcutânea sem danificar a pele, oferecendo um caminho eficaz para aprimorar os contornos do seu corpo, realçando os resultados do seu treino.
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            DETOX Corporal
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Revitalize seu corpo com nosso Detox Corporal, ideal para homens que buscam maximizar o desempenho físico. Com a ajuda de uma manta térmica e argila, este tratamento elimina toxinas, estimula a circulação e potencializa a recuperação muscular auxiliando na obtenção de um físico mais definido e preparado para o desafio seguinte.
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Limpeza de pele corporal
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            A Limpeza de Pele Corporal na Homenz não é apenas sobre limpeza, é sobre preparar sua pele para destacar o resultado de seus esforços físicos. Com uma pele mais limpa e saudável, sua musculatura fica mais evidente, complementando sua aparência saudável.
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Radiofrequência
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            A Radiofrequência corporal na Homenz é o segredo para uma pele mais firme e um contorno corporal mais definido. Utilizando ondas eletromagnéticas de baixa frequência, este tratamento apresenta ótimos resultados, estimulando a produção de colágeno, melhorando a qualidade da pele e destacando a definição muscular.
                        </div>
                        <Helmet>
                            <title>Homenz || Tratamento Corporal</title>
                            <meta name="description" content="Procedimentos estéticos corporais para gordura localizada, definição e força" />
                            <meta nema="keywords" content="homenz americana, clínica estética em americana, clínica estética para homens, Clínica estética exclusiva para homens, saúde e estética masculina, tratamento estético corporal, enzimas para gordura localizada, Criofrequência, Criolipólise, DETOX Corporal, Limpeza de pele corporal, Radiofrequência" />
                        </Helmet>
                    </motion.div>
                : <></>}

<motion.button initial={{opacity: 0}} animate={{opacity: 1}} style={tratamento === 3 ? styles.tituloOn : styles.titulo} onClick={()=>{Abre(tratamento === 3 ? 0 : 3 )}}>Tratamento Facial <img src={tratamento === 3 ? icoArrowDown : icoArrowUp} width='20' /></motion.button>
                {tratamento === 3 ? 
                    <motion.div style={styles.conteudoInterna} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{duration: 1.6}} exit={{ opacity: 0 }}>
                        <img src={facial} width='100%' />

                        <div style={{fontFamily: 'Roboto Italic', fontSize: 22, color: '#53aca6', marginBottom: 15, marginTop: 30}}>
                            Potencialize sua confiança com uma aparência impecável
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 18, color: '#16376e'}}>
                            Descubra os tratamentos estéticos faciais da Homenz, desenvolvidos especialmente para o homem moderno. Com tecnologia de ponta e técnicas avançadas, nossos tratamentos restauram e rejuvenescem sua pele, garantindo um visual natural e sofisticado. Eleve sua autoestima e destaque-se com uma aparência revitalizada.
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Bioestimulador de colágeno
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Revitalize sua pele com o tratamento de Bioestimulador de Colágeno da Homenz. Combata a flacidez e restaure a firmeza com uma mescla biocompatível, segura e indicada para a pele, promovendo uma aparência mais jovem. Ideal para quem busca resultados duradouros e efetivos no rejuvenescimento facial.
                        </div>
                        
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Harmonização facial
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Realce a estrutura do seu rosto com a Harmonização Facial da Homenz. Equilibre suas proporções e destaque seus melhores traços, trazendo harmonia e simetria ao seu rosto. Alcance uma aparência mais atraente com resultados naturais e personalizados.
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Limpeza de pele avançada
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Quer melhorar a saúde e aparência da sua pele? Agende uma Limpeza de Pele Avançada na Homenz. Elimine cravos, impurezas, oleosidade e renove a aparência do seu rosto. Ideal para todos os tipos de pele.
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Microagulhamento facial
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Revitalize sua pele com Microagulhamento Facial. Esse procedimento é utilizado para revitalizar e rejuvenescer o rosto através de um cilindro que provoca micro lesões na pele, estimulando a produção de colágeno e elastina. Esse processo melhora a textura, reduz rugas e cicatrizes e pode ser potencializado com séruns ou cremes. Técnica segura para restaurar a firmeza e vitalidade da sua pele.
                        </div>
                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Peeling de acne / Peeling de diamante / Peeling químico
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            O peeling facial é um tratamento estético que consiste na aplicação de substâncias químicas ou uso de métodos físicos para esfoliar a camada superficial da pele<br /><br />
                            <b>Acne:</b> Combata a acne e suas marcas. Renove a superfície da pele, reduza inflamações e previna futuras erupções. <br />
                            <b>Diamante:</b> Renove sua pele. Remova células mortas e promova a regeneração celular para uma pele mais suave e luminosa. Minimize poros e suavize linhas finas, para um acabamento perfeito. <br />
                            <b>Químico:</b> Diminua manchas, rugas finas e melhore a textura da pele. Um tratamento eficaz para uma aparência rejuvenescida, promovendo uma pele mais uniforme e radiante.
                        </div>

                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Preenchimento de olheiras
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Revitalize seu olhar com o Preenchimento de Olheiras na Homenz. Esse procedimento minimiza a aparência de cansaço, reduzindo sombras e sulcos sob os olhos. Resultados imediatos para um olhar mais descansado e rejuvenescido.
                        </div>

                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Preenchimento de labial
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Valorize seus lábios com o Preenchimento Labial da Homenz. Alcance volume e definição, melhorando o contorno dos lábios de forma natural. Ideal para homens que desejam um aspecto mais jovem e lábios mais atraentes.
                        </div>

                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Radiofrequência
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Revitalize sua pele com a Radiofrequência da Homenz. É um procedimento estético não invasivo que utiliza ondas eletromagnéticas de baixa frequência para aquecer as camadas mais profundas da pele, estimulando a produção de colágeno e elastina, garantindo a firmeza e elasticidade da sua pele.
                        </div>

                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Revitalização facial
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Descubra a Revitalização Facial na Homenz. Hidrate e nutra profundamente sua pele, combatendo sinais de fadiga e aparência cansada. Recupere o brilho e a elasticidade, para um rosto visivelmente mais saudável e energizado.
                        </div>

                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Skinbooster
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            O Skinbooster é um tratamento que envolve a aplicação de ácido hialurônico em áreas específicas da pele para proporcionar hidratação profunda, eliminando o craquelado e as fraturas de uma pele desidratada e melhorando sua qualidade. Diferente dos preenchimentos tradicionais, que são utilizados para dar volume e contorno ao rosto, o Skinbooster é projetado para hidratar a pele de dentro para fora, melhorando sua textura, firmeza e luminosidade.
                        </div>

                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Procedimentos faciais injetáveis
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Suavize linhas de expressão e previna o envelhecimento precoce com procedimentos injetáveis. Estes procedimentos são rápidos e eficazes, relaxam os músculos faciais, reduzindo rugas e proporcionando uma aparência mais jovem e descansada
                        </div>

                        <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                            Tratamento para crescimento de barba
                        </div>
                        <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                            Potencialize o crescimento da sua barba com nosso tratamento especializado. Aumente a densidade e a saúde dos fios, alcançando a barba cheia e definida que você deseja. Perfeito para homens que buscam um visual marcante.
                        </div>

                        <Helmet>
                            <title>Homenz || Tratamento Facial</title>
                            <meta name="description" content="Procedimentos estéticos corporais para gordura localizada, definição e força" />
                            <meta nema="keywords" content="homenz americana, clínica estética em americana, clínica estética para homens, Clínica estética exclusiva para homens, saúde e estética masculina, tratamento estético facial, Bioestimulador de colágeno, Harmonização facial, Limpeza de pele avançada, Microagulhamento facial, Peeling de acne, Peeling de diamante, Peeling químico, Preenchimento de olheiras, Preenchimento labial, Revitalização facial, Radiofrequência, Skinbooster, Procedimentos faciais injetáveis, Tratamento para crescimento de barba" />
                        </Helmet>
                    </motion.div>
                : <></>}

            </motion.div>
        </AnimatePresence>
        </div>
    </Layout>
    )
}

const styles = {
    principal: {
        display: 'flex',
        flexDirection: 'column'
    },
    titulo: {
        fontSize: 20,
        border: 'none',
        backgroundColor: 'transparent',
        display: 'flex',
        backgroundColor: '#f8f8f8',
        color: '#62a3a8',
        borderRadius: 8,
        padding: 20,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tituloOn: {
        fontSize: 20,
        border: '1px solid #ebebeb',
        backgroundColor: 'transparent',
        display: 'flex',
        backgroundColor: '#f8f8f8',
        color: '#16376e',
        borderRadius: 8,
        padding: 20,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    conteudoInterna: {
        padding: 20,
    }
}