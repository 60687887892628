import React, {useState} from "react";
import '../App.css'
import whatsapp from '../assets/whatsapp.png'
import { motion } from "framer-motion"
import UseWhatsapp from 'whatsapp-react-component'
import setaWhats from '../assets/setaWhats.png'
import { TextField } from "@mui/material";
import { isMobile } from "react-device-detect";


export default function Whats() {
    const [showWhats, setShowWhats] = useState(false)
    const mobileNumber = '+551934622003'
    const [message, setMessage] = useState('')

    
    const onSubmit = () => {
        UseWhatsapp(mobileNumber, message)
    }

    return(
        <div style={{display: 'flex', position: "fixed", bottom: 30}}>
        <button className={isMobile ? 'boxRoundedMobile' : 'boxRounded'} style={{marginRight: 20}} onClick={()=>{setShowWhats(!showWhats)}}>
            <img src={whatsapp} width={isMobile ? '30' : '70'} />
        </button>
        
                {showWhats ?
                    <div className="boxRounded2" style={{ marginTop: -160, marginLeft: 0, display: 'flex', alignItems: 'flex-start', position: 'absolute', zIndex: 999}}>
                        <form onSubmit={onSubmit}>   
                            <div style={{fontSize:14}}>Mande seu recado:</div>
                            
                                <TextField size='small' type="text" value={message} onChange={(event)=>setMessage(event.target.value)} name='message' style={{backgroundColor: '#69c8c6', borderRadius: 8,}} />        
                                <br />
                                <input type="submit" value="Enviar" style={{ backgroundColor: '#5abdc4', border: 'none', borderRadius: 8, color: '#ffffff', padding: 10, marginTop: 10, marginBottom: 20}} />
                        </form>
                    </div>
                    
                : null }
                
        
        </div>
    )
}
