import React from "react";
import '../App.css';
import { AnimatePresence, motion } from "framer-motion";
import Layout from "../component/Layout";
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'AW-16639841404'
}
TagManager.initialize(tagManagerArgs)


export default function QuemSomos() {
    window.dataLayer.push({
        event: 'pageview'
      });
    
    return(
    <Layout>
        <Helmet>
            <title>Homenz || Quem Somos</title>
            <meta name="description" content="A 1ª rede premium especialista nas necessidades" />
            <meta nema="keywords" content="homenz americana, clínica estética em americana, clínica estética para homens, Clínica estética exclusiva para homens, saúde e estética masculina, tecnologia avançada em estética" />
      </Helmet>
        <div style={{minHeight: 900}}>
        <AnimatePresence>
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.5, duration: 2}} exit={{opacity: 0}}>
                <div style={{fontFamily: 'Roboto Italic', fontSize: 22, color: '#689ea2', textAlign: 'center'}}>A 1ª rede premium especialista nas necessidades<br />de estética e saúde masculina.</div>
                
                <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>A essência da Homenz.</div>
                <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>Bem-vindo à HOMENZ, o espaço onde a excelência em estética masculina encontra inovação, conforto e qualidade. Nossa missão é transformar e inovar a experiência do autocuidado masculino, oferecendo serviços de alta qualidade em um ambiente moderno e acolhedor. </div>
                <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798', marginTop: 20}}>Na Homenz Americana, contamos com uma equipe altamente qualificada, composta por esteticistas, biomédicos e médicos especializados. Nossos profissionais são especialistas em estética masculina, constantemente treinados e atualizados com as mais recentes tendências e técnicas do mercado, proporcionando tratamentos seguros e eficazes. Cada membro da nossa equipe é apaixonado pelo que faz, garantindo um atendimento personalizado e atencioso para todos os nossos clientes.</div>
                
                <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>A única rede de clínicas especializada em saúde e estética masculina do país.</div>
                <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>Vamos além de tratamentos e produtos premium, criando experiências que celebram a autenticidade masculina. Nosso compromisso é ser mais do que uma clínica: somos um espaço onde homens encontram cuidado, privacidade e resultados excepcionais.
                </div>
                
                <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                    Compromisso com a inovação
                </div>
                <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                    Investimos em tecnologia de ponta para oferecer tratamentos eficazes e seguros.
                    <br />
                    Nosso compromisso com a inovação molda o futuro da estética masculina, assegurando que você receba o melhor em cuidados e resultados.
                </div>
                
            
                <div style={{fontFamily: 'Roboto Medium', fontSize: 18, color: '#10336f', marginBottom: 15, marginTop: 40}}>
                    Diferenciais da Clínica
                </div>
                <div style={{fontFamily: 'Roboto', fontSize: 16, color: '#879798'}}>
                    Tecnologia Avançada: Equipamentos modernos e técnicas inovadoras.
                    <br />
                    Ambiente Confortável: Espaço acolhedor e profissional.
                    <br />
                    Atendimento Personalizado: Atenção personalizada e exclusiva.
                </div>
                
            <br />
            <br />
            <br />
            <br />
            <br />
            </motion.div>
        </AnimatePresence>
        </div>
    </Layout>
    )
}