import * as React from 'react';
import '../App.css'
import { isMobile } from 'react-device-detect';

const Depoimentos = () => {

const depoimento = [
    {
        nome: 'Henrique',
        profissao: null,
        texto: 'Quando eu ouvi falar da clínica Homenz, eu logo me interessei. Tô fazendo um tratamento facial fantástico! Fui super bem atendido desde a recepção, fenomenal, sem palavras. Vem você também conhecer a Homenz, você não vai se arrepender!',
        foto: 'henrique.png'
    },
    {
        nome: 'Jaime Braído',
        profissao: '',
        texto: 'Conheci a Clínica Homenz pela internet e, quando abriu em Americana, fiquei feliz e fui conferir. O tratamento cabe no meu bolso e está dando ótimos resultados. Estou na quarta sessão e satisfeito. O atendimento é excelente, as meninas da clínica são nota 10. Como morador de Americana, recomendo! Ótima escola, a melhor opção.',
        foto: 'JaimeBraido.png'
    },
    {
        nome: 'Rafael do Prado',
        profissao: '',
        texto: 'Iniciei meu tratamento na Clínica Homenz Americana em maio de 2024, com dúvidas sobre os resultados. Na primeira sessão, fiquei inseguro sobre os procedimentos, mas após ver as fotos comparativas na segunda sessão, comecei a acreditar. Agora, na quarta sessão, estou impressionado com os resultados e ansioso pelo final. O atendimento personalizado é excelente, e estou muito satisfeito com a experiência e os resultados obtidos.',
        foto: 'RafaelDoPrado.png'
    },
    {
        nome: 'Cleiton Belatine',
        profissao: '',
        texto: 'Depois de anos enfrentando calvície, fiz implantes capilares em 2020 e 2023. Em 2024, conheci a Clínica Homenz em Americana, onde recebi um tratamento personalizado e atencioso. A queda de cabelo reduziu e novos fios cresceram visivelmente. Hoje, me sinto mais confiante e satisfeito. Recomendo a Clínica Homenz para quem busca tratamento capilar eficaz',
        foto: 'CleitonBelatine.png'
    }
    
]   
  return (
    <div style={styles.depoimentos}>
    {depoimento.map((dep, index)=>(
        <div style={styles.depoimento}>
            <img src={require('../assets/fotosClientes/'+dep.foto)} width='120' style={styles.foto} />
            <div style={{color: '#4283d6', fontWeight: 600}}>{dep.nome}</div>
            <div style={{color: '#53aca6'}}>{dep.profissao}</div>
            <div style={{color: '#79bcc1', width: 250, textAlign: 'center', fontSize: 13}}>"{dep.texto}"</div>
        </div>   
    ))}
    </div>
  )
}

export default Depoimentos

const styles = {
    depoimentos: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        marginTop: isMobile ? 50 : 150,
    },
    depoimento: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: 30
    },
    foto: {
        marginBottom: 40,
        borderRadius: 100,
        maxWidth: 150,
        borderWidth: 3,
        borderColor: '#ffb900'
    }
}
