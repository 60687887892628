import React from "react";
import '../App.css'

import rodapeSombra from '../assets/rodapeSombra.png'
import instagram from '../assets/instagram.png'
import logo from '../assets/logo.png'

export default function Footer() {

    return(
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', color: '#99a8a9', paddingBottom: 30}}>
            <img src={rodapeSombra} width='100%' style={{marginTop: 80, marginBottom: 20}} />
            <div style={{textAlign: 'center'}}>
            Avenida de Cillo, 492 - Vila Pavan - Americana/SP <br />
            Horário de Funcionamento: Segunda a Sexta: das 10h-20h / Sábado: das 08h-12h<br />
            (19) 3462-2003
            <br />
            <a href="https://www.instagram.com/homenz.americana/" target="_blank" rel="noopener noreferrer">
              <img src={instagram} width='25' style={{marginTop: 30}} />
            </a>
            <br />
            <img src={logo} width='125' style={{marginTop: 30}} />
            </div>
          </div>
    )
}